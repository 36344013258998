// Tasks module
import axios from "axios";
import store from "/src/store/index";
const resource_uri_permissao = "/listarmedicoagenda/";
const resource_uri = "/listarmedico/";
const resource_local = "/listarlocal";



const getters = {
  allMedicos: (state) => state.medicos,
  medicoSelecionado: (state) => state.medicoSelecionado,
  getMedicosFilter: (state) => (nome) => {
    if (nome == null || nome == "") {
      return state.medicos;
    }
    return state.medicos.filter((t) =>
      t.nome.toUpperCase().includes(nome.toUpperCase())
    );
  },
  getMedico: (state) => (id) => {
    if (id == null || id == "") {
      return null;
    }
    var filtro = state.medicos.filter((t) => t.id === Number(id));
    if (filtro.length > 0) {
      return filtro[0];
    }
    return null;
  },
};

const actions = {
  async fetchMedicos({ commit }, {urlBackEnd, email_usuario}) {
    
    const response0 = await axios.get(urlBackEnd+"/dadosusuario/"+email_usuario,{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
    commit("setDadosUsuarios", response0.data);
    if (urlBackEnd === "https://konsist.konsistnfe.com.br" || urlBackEnd === "https://vapsicologia.konsistweb.com.br:2096"
    || response0.data.id_medico_usuario === 0) {
      const response = await axios.get(urlBackEnd+resource_uri,{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`,'Content-Type': 'application/json'  } });
      commit("setMedicos", response.data);

  }else {
    const response = await axios.get(urlBackEnd+resource_uri_permissao+store.state.configuracoes.email,{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`,'Content-Type': 'application/json'  } });
    commit("setMedicos", response.data);
  }
  },
  async fetchLocais({ commit }, {urlBackEnd}) {
    const response2 = await axios.get(urlBackEnd+resource_local,{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}` } });
      commit("setLocal", response2.data);
  },
};
const state = {
  medicos: [],
  local: [],
  medicoSelecionado: null,
  dadosusuarios: {},
};
const mutations = {
  setMedicos: (state, medicos) => (state.medicos = medicos),
  setLocal: (state, local) => (state.local = local),
  setDadosUsuarios: (state, dadosusuarios) => (state.dadosusuarios = dadosusuarios),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
