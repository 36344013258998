<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
      style="height: 100%;"
    >
      <mobile-menu slot="content"></mobile-menu>
      <v-list  nav>
        <sidebar-link to="/dashboard">
          <md-icon>home</md-icon>
          <p>&nbsp;&nbsp;&nbsp;Página inicial</p>
        </sidebar-link>
      </v-list>
      <v-list nav>
        <sidebar-link to="/usuario"> 
          <v-icon>mdi-account</v-icon>
          <p>&nbsp;&nbsp;&nbsp;Usuário</p>
        </sidebar-link>
      </v-list>      
      <v-list  nav>
                <v-list-group :value="false" no-action sub-group prepend-icon="mdi-bottle-tonic-plus">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="white--text normal-font">Agendas</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-text-field
                  label="Pesquisar agenda"
                  hide-details="auto"
                  v-model="pesquisaMedico"
                  color="green"
                  class="textoPesquisa"
                ></v-text-field>

                <v-list-item
                  v-for="item in uniqueMedicos"
                  :key="item.id"
                  link
                  @click="setMedico(item)"
                  :to="`/agendatable/` + item.id"
                >
                  <v-list-item-title
                    v-text="item.nome"
                    style="color: white; font-size: 0.9em;"
                  ></v-list-item-title>
                </v-list-item>
          </v-list-group>
      </v-list>  
      <v-list nav>
        <v-list-item class="feedback-item white--text" @click="openFeedbackPopup">
            <v-icon>mdi-comment-account</v-icon>
            <p>&nbsp;&nbsp;&nbsp;Enviar Feedback</p>
        </v-list-item>
      </v-list>   
      <v-list nav class="float-end" style="height: 1600%;">
        <sidebar to="/login?redirect=%2Fdashboard" style="bottom: 21px;
          position: static;
          width: 100%;
          left: 0px;
          background: linear-gradient(45deg, black, rgb(255 255 255 / 70%));">
          <md-list-item href="" @click="sairSistema">
                <v-icon aria-hidden="false"> login </v-icon>
                <p class="sair">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sair</p>
          </md-list-item>
        </sidebar>
      </v-list>
    </side-bar>

    <div class="main-panel">
      <v-dialog v-model="showUpdatePopup" max-width="600">
        <v-card>
          <v-card-title>Atualização Disponível</v-card-title>
          <v-card-text>
            A versão do aplicativo está desatualizada. Entre em contato com o suporte para solicitar a atualização da API e ter acesso a mais funcionalidades.
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="showUpdatePopup = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showFeedbackPopup" max-width="600">
        <v-card>
          <v-card-title>Enviar Feedback</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="feedbackText"
              label="Envie seu feedback ou sugestões de melhoria."
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="sendFeedback" :disabled="feedbackText.length < 5">Enviar</v-btn>
            <v-btn @click="showFeedbackPopup = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    FixedPlugin,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      medicosFiltrados: [],
      pesquisaMedico: "",
      showUpdatePopup: false,
      showFeedbackPopup: false,
      feedbackText: "",
    };
  },
  async created() {
  this.$store.state.configuracoes.loading = true;

  try {
    if (this.$store.state.medicos.medicos.length === 0) {
      await this.$store.dispatch("fetchMedicos", {
        urlBackEnd: this.$store.state.configuracoes.urlBackEnd,
        email_usuario: this.$store.state.configuracoes.email,
      });
    }
  } catch (error) {
    // Tratar erros
    alert("Não foi possível carregar a agenda. Entre em contato com o suporte.");
    // Possivelmente exibir uma mensagem de erro para o usuário
  } finally {
    try {
      await this.$store.dispatch("fetchLocais", {
        urlBackEnd: this.$store.state.configuracoes.urlBackEnd,
      });
    } catch (error) {
      // Tratar erros de fetchLocais
      /*this.$notify({
                group: 'foo',
                title: "Não foi possível carregar os locais de atendimento",
                text: '',
                type: 'warn',
              })*/
    }

    this.$store.state.configuracoes.loading = false;

    try {
      const versao_api = this.$store.state.medicos.dadosusuarios.versao_api;
      const versao_inteiro = parseInt(versao_api.replace(/\./g, ''), 10);
      const ultima_versao_api = this.$store.state.configuracoes.usuarioLogado.versao_api;
      const ultima_versao_inteiro = parseInt(ultima_versao_api.replace(/\./g, ''), 10);
      await this.$store.dispatch("versaoApi", {
        urlBackEnd: this.$store.state.configuracoes.urlBackEnd,
      });
      if (versao_inteiro < ultima_versao_inteiro) {
        this.showUpdatePopup = true;
      } else {
      }
    } catch (error) {
      // Tratar erros de versaoApi
      this.$notify({
                group: 'foo',
                title: "Não foi possível verificar a versão da API",
                text: '',
                type: 'warn',
              })
    }
  }
},
  computed: {
  ...mapGetters(["getMedicosFilter"]),
  uniqueMedicos() {
    const medicosFilterResult = this.getMedicosFilter(this.pesquisaMedico);

    if (Array.isArray(medicosFilterResult)) {
      // Se for um array, aplica a lógica de filtragem
      return medicosFilterResult.filter((item, index, self) => {
        return index === self.findIndex((t) => (
          t.nome === item.nome
        ));
      });
    } else if (medicosFilterResult && typeof medicosFilterResult === "object") {
      // Se for um objeto único, coloca ele em um array
      return [medicosFilterResult];
    } else {
      // Tratar o caso em que medicosFilterResult não é um array ou um objeto válido(não fiz tratamento)
      return [];
    }
  },
  },
  methods: {
    setMedico(item) {
      this.$store.state.medicos.medicoSelecionado = item;
      this.$sidebar.displaySidebar(false);
    },
    sairSistema() {
      
      this.$store.commit("LOGOUT", null);
      this.$cookies.remove("token_api");
      this.$cookies.remove("url_back");
      this.$cookies.remove("token_back");
      this.$cookies.remove("email_user");
      this.$cookies.remove("nome_user");
      this.$cookies.remove("cliente_user");
      this.$cookies.remove("cliente_id");
      this.$cookies.remove("usuario_id");
      window.location.href = window.location.href;
      this.$router.push("/login?redirect=%2Fdashboard");
    },
    openFeedbackPopup() {
      this.showFeedbackPopup = true;
    },
    sendFeedback() {
      this.$store
        .dispatch("enviaSugestao", {
          urlBackEnd: this.$store.state.configuracoes.urlBackEnd,
          idCliente: this.$store.state.configuracoes.clienteid,
          idUsuario: this.$store.state.configuracoes.id,          
          sugestao: this.feedbackText,

        })
        .then(() => {
          this.$store
            .dispatch("usuarioAcesso",{
                idCliente: this.$store.state.configuracoes.clienteid,
                idUsuario: this.$store.state.configuracoes.id,
                nomeTela: "Feedback enviado",
            });
        alert("Feedback enviado com sucesso");
        // Redirecionar para a página anterior
        })
        .catch(error => {
        this.$notify({
          group: 'foo',
          title: 'Erro ao enviar feedback',
          text: '',
          type: 'warn',
        });
        })
        .finally(() => {
            this.$store.state.configuracoes.loading = false;
              //this.$store.state.configuracoes.loading = false;
              //this.refreshEventosDia(); 
          });
      // Lógica para enviar o feedback
      this.showFeedbackPopup = false;
    },    
  },
};
</script>
<style >
.v-sheet.v-list {
  background: transparent !important;
}
.v-list-item__title {
  color: white;
}
.textoPesquisa {
  margin-left: 43px;
}
.textoPesquisa .v-label {
  color: rgb(8, 168, 75) !important;
  opacity: 1;
}
.textoPesquisa input {
  color: rgb(243, 243, 243) !important;
}
.sair {
  color:white
}

.sidebar-wrapper{
  height: 100%;
}
.md-list{
  height: 100%;
}
.v-sheet.v-list {
  background: transparent !important;
  justify-content: flex-end;
}
.feedback-item {
  margin-left: 25px; /* Move o item para a direita */
}
</style>

