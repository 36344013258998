// Tasks module
import axios from "axios";
import store from "/src/store/index";
const resource_uri = "/listaragenda";
const grava_prontuario = "/prontuario";
const lista_prontuario = "/listarprontuario"
const visualiza_prontuario = "/mostrarprontuario"
const modelo_prontuario = "/modeloprontuario"

const state = {
  agendaDia: [],
  diaSelecionado: new Date(),
  permissao: {},
  permissaoProntuario: {},
  listaDeProntuarios: [],
  visualizarProntuarios: [],
  modeloProntuario: [],
  ExcluirProntuarioRetorno: [],
};

const getters = {
  getPermissao: (state) => state.permissao,
  getPermissao: (state) => state.permissaoProntuario,
  getAgendaDia: (state) => state.agendaDia,
  diaSelecionado: (state) => state.diaSelecionado,
  getPaciente: (state) => (Id) => {
    if (Id == null || Id == "") {
      return null;
    }
    var filtro = state.agendaDia.filter((t) => t.Id === Number(Id));
    if (filtro.length > 0) {
      return filtro[0];
    }
    return null;
  },
};

const actions = {
  async fetchAgendaDia({ commit }, { urlBackEnd, idMedico , dia , qtdDias , }) {
    
    const response = await axios.post(urlBackEnd+resource_uri,{
    "idMedico":idMedico,
    "data": dia,
    "qtdDias":0,
    "emailUsuario": store.state.configuracoes.email,
    "mostrarHVazio": false
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setAgendaDia", response.data)
    
     const permissaoProntuario = await axios.get(urlBackEnd+"/dadosvisiveis",{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setpermissaoProntuario", permissaoProntuario.data)
  },

  async gravaProntuario({ commit }, { urlBackEnd, chave, idMedico, emailUsuario, data, hora, texto , idquestionario}) {
    const response = await axios.post(urlBackEnd+grava_prontuario,{
      "chave": chave,  
      "idMedico":idMedico,
      "emailUsuario": emailUsuario,
      "data": data,
      "hora": hora,
      "texto": texto,
      "idquestionario": idquestionario,
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     //commit("resposta", response.data)
  },

  async modeloDeProntuario({ commit }, { urlBackEnd, idMedico, emailUsuario}) {
    const response = await axios.post(urlBackEnd+"/modeloprontuario",{  
      "idMedico":idMedico,
      "emailUsuario": emailUsuario,
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setmodeloProntuario", response.data)
  },

  async listarProntuario({ commit }, { urlBackEnd, idpaciente, emailUsuario}) {
    const response = await axios.post(urlBackEnd+lista_prontuario,{  
      "idpaciente":idpaciente,
      "emailusuario": emailUsuario,
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setlistaDeProntuarios", response.data)
  },

  async visualizarProntuario({ commit }, { urlBackEnd, idpaciente, idquestionario, idprontuario, idmedico, emailUsuario}) {
    const response = await axios.post(urlBackEnd+visualiza_prontuario,{  
      "idpaciente":idpaciente,
      "idquestionario":idquestionario,
      "idprontuario":idprontuario,
      "idmedico":idmedico,
      "emailusuario": emailUsuario,
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setvisualizarProntuarios", response.data)
  },

  async excluirProntuario({ commit }, { urlBackEnd, idprontuario, idmedico, emailUsuario }) {
    try {
      const response = await axios.delete(urlBackEnd + "/prontuario", {
        headers: {
          Authorization: `Bearer ${store.state.configuracoes.tokenBack}`,
          'Content-Type': 'application/json'
        },
        data: {
          idprontuario: idprontuario,
          idmedico: idmedico,
          emailusuario: emailUsuario
        }
      });
  
      commit("setExcluirProntuarioRetorno", response.data);
    } catch (error) {
      //NÃO FAZ NADA AQUI
    }
  },

  async agendaStatus({ commit }, { urlBackEnd, idPaciente , status, emailUsuario }) {
    const response = await axios.post(urlBackEnd+"/status",{
      "chave": idPaciente,
      "status": status,
      "emailusuario": emailUsuario
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
  },

  async agendaPermissao({ commit }, { urlBackEnd }) {
    const response = await axios.get(urlBackEnd+"/dadosvisiveis",{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
    commit("setPermissao", response.data)
  },   
};

const mutations = {
  setAgendaDia: (state, agendaDia) => (state.agendaDia = agendaDia),
  setDiaSelecinado: (state, diaSelecionado) => (state.diaSelecionado = diaSelecionado),
  setPermissao: (state, permissao) => (state.permissao = permissao),
  setpermissaoProntuario: (state, permissaoProntuario) => (state.permissaoProntuario = permissaoProntuario),
  setlistaDeProntuarios: (state, listaDeProntuarios) => (state.listaDeProntuarios = listaDeProntuarios),
  setvisualizarProntuarios: (state, visualizarProntuarios) => (state.visualizarProntuarios = visualizarProntuarios),
  setExcluirProntuarioRetorno: (state, ExcluirProntuarioRetorno) => (state.ExcluirProntuarioRetorno = ExcluirProntuarioRetorno),
  setmodeloProntuario: (state, modeloProntuario) => (state.modeloProntuario = modeloProntuario),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
