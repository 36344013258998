<template>
<footer class="footer">
  <div class="container">
    <div class="copyright text-center">
      &copy; {{ new Date().getFullYear() }}
      <a href="https://www.konsist.com.br" target="_blank">Konsist Solução em Tecnologia</a>,
      <i class="fa fa-heart heart"></i> A Konsist cuida da saúde da sua empresa, enquanto você cuida de seus negócios.
    </div>
    <nav>
      <ul>
        <li>
          <a href="https://konsist.com.br/sobre" target="_blank"> Sobre a Konsist </a>
        </li>
        <li>
          <a href="https://konsist.com.br/produtos" target="_blank"> Produtos </a>
        </li>
        <li>
          <a href="https://konsist.com.br/clientes" target="_blank"> Parceiros </a>
        </li>
      </ul>
    </nav>
  </div>
</footer>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style></style>
<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os filhos horizontalmente */
  }

  nav {
    width: 100%; /* Define a largura máxima para ocupar a largura total da .container */
    text-align: center; /* Centraliza o conteúdo dentro da nav */
    margin-top: 10px; /* Adapte conforme necessário para a separação desejada */
  }

  nav ul {
    list-style: none; /* Remove os marcadores de lista padrão */
    padding: 0; /* Remove o preenchimento padrão */
  }

  nav ul li {
    display: inline-block; /* Exibe os itens da lista em linha (um ao lado do outro) */
    margin-right: 10px; /* Adapte conforme necessário para a separação desejada entre os itens */
  }

  nav a {
    text-decoration: none; /* Remove a sublinhado padrão dos links */
  }
</style>
