<template>
  <v-main>
  <v-dialog
      v-model="this.$store.state.configuracoes.loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" light>
        <v-card-text style="color: white">
          Processando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="this.$store.state.configuracoes.loading"></v-overlay>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
  </transition>
</v-main>
</template>
<script>
export default {};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>