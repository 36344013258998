<template>
  <div>
    <md-table v-model="users" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Nome">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Agendamentos">{{ item.salary }}</md-table-cell>
        <md-table-cell md-label="Local">{{ item.country }}</md-table-cell>
        <md-table-cell md-label="Telefone">{{ item.city }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "ordered-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: [],
      users: [
        {
          id: 1,
          name: "Funcionário teste",
          salary: "14",
          country: "Asa Sul",
          city: "61 988772345",
        },
        {
          id: 2,
          name: "Funcionário teste",
          salary: "14",
          country: "Asa Sul",
          city: "61 988772345",
        },
        {
          id: 3,
          name: "Funcionário teste",
          salary: "14",
          country: "Asa Sul",
          city: "61 988772345",
        },
        {
          id: 4,
          name: "Funcionário teste",
          salary: "14",
          country: "Asa Sul",
          city: "61 988772345",
        },
      ],
    };
  },
};
</script>
